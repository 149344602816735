/*
|--------------------------------------------------------------------------
| UNSUPPORTED BROWSERS THAT SUCK THE LIFE OUT OF DEVELOPERS
|--------------------------------------------------------------------------
| @package twgx
*/

.is-ie {
  .site-header {
    background-color: $white !important;
  }

  .card {
    .card-img-top { flex-shrink: 0; }
  }

  .navbar-primary {
    .dropdown-menu {
      left: 0 !important;
      right: 0 !important;
    }
  }

  .dropdown-container {
    height: $dropdown-container-height;
  }

  .menu-icon {
    width: 30px;
    height: auto;
    align-self: center;
  }

  .is-edge {}

  .is-ie-11 {}
}
