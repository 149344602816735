/*
|--------------------------------------------------------------------------
| SURVEY RESULTS
|--------------------------------------------------------------------------
| @package twgx
*/

$survey-red:     #e15b50;
$survey-orange:  #efa05b;
$survey-blue:    #78bee2;
$survey-green:   #7bc26f;

.survey-results {
  .lead {
    line-height: 1.4;
  }
}

.survey-score-wrap {
  text-align: center;
}

.survey-score {
  display: block;
  font-size: 4.5em;
  line-height: 1;
  margin-bottom: 25px;
}

.survey-legend {
  display: flex;
  font-size: 14px;
}

.survey-legend-block {
  flex: 1;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  margin: 0 4px;
}

.survey-legend-range {
  border-bottom: 10px solid;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
}

.survey-legend-range .survey-legend-max {
  margin-left: auto;
}

.survey-color-red {
  border-bottom-color: $survey-red;
  color: $survey-red;
}

.survey-color-orange {
  border-bottom-color: $survey-orange;
  color: $survey-orange;
}

.survey-color-blue {
  border-bottom-color: $survey-blue;
  color: $survey-blue;
}

.survey-color-green {
  border-bottom-color: $survey-green;
  color: $survey-green;
}

@media(max-width: 640px) {
  .survey-legend-text { display: none; }
}