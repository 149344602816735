/*
|--------------------------------------------------------------------------
| TYPE
|--------------------------------------------------------------------------
| @package twgx
*/

p {
  font-size: 0.9375rem;

  @include media-breakpoint-up(md) {
    font-size: $paragraph-size;
  }
}

h1, .h1 {
  font-weight: 800;
  @include media-breakpoint-down(sm) {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-weight: 700;
}

h3, .h3 {
  font-weight: 600;
}

h4, .h4 {
  font-weight: 700;
}

.lead {
  color: color('gray-dark');
}

.legal {
  font-size: 0.75rem;
}

.text-decoration-underline { text-decoration:underline !important; }

.text-eyebrow {
  @include media-breakpoint-down(sm) {
    font-size: 0.6875rem;
  }

  color: theme-color('secondary');
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;

  > a {
    color: inherit!important;
    border-bottom: inherit!important;
    font-weight: inherit!important;
  }

  .bull {
    color: color('gray-dark');
  }
}

.text-menu-heading {
  @extend .text-eyebrow;
  color: color('gray-dark');
}

.text-large {
  font-size: 1.25em !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-small {
  font-size: .9rem !important;
}

.card-title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  color: color('gray-dark');

  > a {
    color: inherit;
  }
}

.card-text {
  font-size: 0.9375rem;
  line-height: 1.5;
  color: color('gray-light');
}

.post-meta {
  font-size: 0.8125rem;

  .post-author {
    text-transform: uppercase;
    font-weight: 600;
    color: color('gray-dark');
  }
}

.footnote,
.note {
  p {
    font-size: 80%;
  }

  a {
    @extend .btn;
    @extend .btn-link;
  }
}

.font-weight-semi-bold { font-weight:600; }