/*
|--------------------------------------------------------------------------
| THEME VARIANTS
|--------------------------------------------------------------------------
| @note This sets potential background themes.
| @package twgx
*/

.bg-primary,
[data-theme="primary"] {
  background-color: theme-color('primary');
}

.bg-primary-light,
[data-theme="primary-light"] {
  @include bg-gradient($primary-light, #F6FBFD, 180deg);
}

.bg-secondary,
[data-theme="secondary"] {
  background-color: theme-color('secondary');
}

.bg-secondary-light,
[data-theme="secondary-light"] {
  background-color: $secondary-light;
}

.bg-light,
[data-theme="light"] {
  background-color: $white;
}

.bg-dark,
[data-theme="dark"] {
  @include bg-gradient(color('dark-1'), color('dark-2'), 45deg);
}

.bg-accent,
[data-theme="accent"] {
  @include bg-gradient(color('accent-1'), color('accent-2'), 180deg);
}

.bg-lighten,
[data-theme="lighten"] {
  background-color: color('lighten');
}

.bg-darken,
[data-theme="darken"] {
  background-color: color('darken')
}

.bg-dark,      [data-theme="dark"],
.bg-primary,   [data-theme="primary"],
.bg-secondary, [data-theme="secondary"] {
  .card-title,
  .card-text,
  a, a:hover,
  .btn-link, .btn-link:hover {
    color: $white;
  }
}
