/*
|--------------------------------------------------------------------------
| BASE
|--------------------------------------------------------------------------
| @note Base scaffolding for site structure.
| @package twgx
*/
html { font-size:16px; }

//body { padding-bottom:1.25rem;
//  @media (min-width: 1580px) { padding-bottom:0; }
//}

/*
|--------------------------------------------------------------------------
| WRAPPER
|--------------------------------------------------------------------------
*/
.wrapper {
  overflow-x: hidden;
}

/*
|--------------------------------------------------------------------------
| BANNER
|--------------------------------------------------------------------------
*/
.banner {
  background: color('secondary');

  .banner--text {
    color: $white;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 0.75rem;
    }
  }
  .banner--link {
    color: $white;
    text-decoration: underline;
    white-space: nowrap;
  }
}

/*
|--------------------------------------------------------------------------
| SITE HEADER
|--------------------------------------------------------------------------
*/
.site-header { width:100% !important;
  transform:translateZ(0); // @hack - make position fixed behalf relative to this container (.dropdown-menu)
  backdrop-filter: saturate(110%) blur(16px);
  transition: background 250ms ease;
  background: rgba($white, .9);
  box-shadow: 0 .125rem .25rem rgba($black, .025);
  @include media-breakpoint-up(md) { border-bottom:1px solid $border-color; box-shadow:none; }
  &.nav-open { background:$white; }
  &:hover {
    @include media-breakpoint-up(md) { background:$white; }
  }
}

/*
|--------------------------------------------------------------------------
| HERO
|--------------------------------------------------------------------------
*/
.hero {
  padding-top: 2rem!important;
  padding-bottom: 2rem!important;
  text-align: center;

  p {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: 4rem !important;
  }

  &.has-image {
    margin-bottom: $hero-image-spacing;

    .section-general--image,
    .animation-wrapper {
      margin-bottom: -$hero-image-spacing;
    }
  }
}

/*
|--------------------------------------------------------------------------
| CONTENT
|--------------------------------------------------------------------------
*/
.content {
  margin-top: 2rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.container-narrow {
  @extend .container;
}

/*
|--------------------------------------------------------------------------
| FOOTER
|--------------------------------------------------------------------------
*/
.footer {
  .navbar-logo {
    @include media-breakpoint-down(sm) { max-width:186px; }
  }
  .legal {
    .copyright {
      flex-basis: 100%;

      @include media-breakpoint-up(md) {
        flex-basis: auto;
      }
    }
    .nav-item { white-space:nowrap; }
  }
}

.footer-menu {
  .menu {
    list-style: none;
    padding-left: 0;
    font-size: 0.75rem;

    .menu-item {
      margin-bottom: 0.5rem;
      @include media-breakpoint-down(md) { line-height:1.45; }

      a {
        color: color('gray-light');
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| GOOGLE: RECAPTCHA BADGE
|--------------------------------------------------------------------------
*/
.grecaptcha-badge {
  display: none !important;
}