/*
|--------------------------------------------------------------------------
| ANIMATION
|--------------------------------------------------------------------------
| @package twgx
*/

@media screen {
  [data-uk-scrollspy*='anim-']:not([data-uk-scrollspy*='target']) { opacity:0; }
  [data-uk-scrollspy*='target'] > * { opacity:0;
    &.uk-scrollspy-inview { opacity:1 !important; }
  }
}
[data-uk-scrollspy*='target'] > * { @extend .anim-properties; }
.anim-properties, [class*='anim-'] { animation-duration:$duration-fast; animation-timing-function:ease-out; animation-fill-mode:both; backface-visibility:hidden; }
.anim-delay   { animation-delay:$duration-fast; }
.anim-none    { animation:none !important; }
.anim-reverse { animation-direction:reverse !important; }
.anim-fill-back { animation-fill-mode:backwards !important; }


/**
 * Animation classes
 *
 * Higher specificity (!important) needed because of reverse modifier
 */
.anim-fade            { animation-name:fade; @extend .anim-properties; }
.anim-scale-x         { animation-name:scale-x; @extend .anim-properties; }
.anim-rotate-x        { animation-name:rotate-x; @extend .anim-properties; }
.anim-scale-up        { animation-name:fade-scale-02; @extend .anim-properties; }
.anim-scale-down      { animation-name:fade-scale-18; @extend .anim-properties; }
.anim-slide-top       { animation-name:fade-top; @extend .anim-properties; }
.anim-slide-bottom    { animation-name:fade-bottom; @extend .anim-properties; }
.anim-slide-left      { animation-name:fade-left; @extend .anim-properties; }
.anim-slide-right     { animation-name:fade-right; @extend .anim-properties; }
.anim-move-top        { animation-name:move-top; @extend .anim-properties; }
.anim-move-bottom     { animation-name:move-bottom; @extend .anim-properties; }
.anim-move-left       { animation-name:move-left; @extend .anim-properties; }
.anim-move-right      { animation-name:move-right; @extend .anim-properties; }
.anim-in-left         { animation-name:in-left; @extend .anim-properties;}
.anim-in-right        { animation-name:in-right; @extend .anim-properties; }
.anim-in-top          { animation-name:in-top; @extend .anim-properties; }
.anim-scale           { animation-name:scale-12; @extend .anim-properties; }
.anim-shake           { animation-name:shake; @extend .anim-properties; }
.anim-spin            { animation-name:fade-scale-rotate; @extend .anim-properties; }
.anim-bg              { animation-name:bg-gradient; @extend .anim-properties; }


/**
 * Origin modifiers
 */
.anim-top-left      { transform-origin:0 0; }
.anim-top-center    { transform-origin:50% 0; }
.anim-top-right     { transform-origin:100% 0; }
.anim-middle-left   { transform-origin:0 50%; }
.anim-middle-right  { transform-origin:100% 50%; }
.anim-bottom-left   { transform-origin:0 100%; }
.anim-bottom-center { transform-origin:50% 100%; }
.anim-bottom-right  { transform-origin:100% 100%; }


@keyframes bg-gradient {
  0%   { background-position:0% 50%; }
  50%  { background-position:100% 50%; }
  100% { background-position:0% 50%; }
}


/**
 * Fade keyframes
 */
@keyframes fade {
  0%   { opacity:0; }
  100% { opacity:1; }
}

@keyframes fade-top {
  0%   { opacity:0; transform:translateY(-33%); }
  100% { opacity:1; transform:translateY(0); }
}

@keyframes fade-bottom {
  0%   { opacity:0; transform:translateY(33%); }
  100% { opacity:1; transform:translateY(0); }
}

@keyframes fade-left {
  0%   { opacity:0; transform:translateX(-33%); }
  100% { opacity:1; transform:translateX(0); }
}

@keyframes fade-right {
  0%   { opacity:0; transform:translateX(33%); }
  100% { opacity:1; transform:translateX(0); }
}

@keyframes move-top {
  0%   { opacity:0; transform:translateY(-6px); }
  100% { opacity:1; transform:translateY(0); }
}

@keyframes move-bottom {
  0%   { opacity:0; transform:translateY(10%); }
  100% { opacity:1; transform:translateY(0); }
}

@keyframes move-left {
  0%   { opacity:0; transform:translateX(-6px); }
  100% { opacity:1; transform:translateX(0); }
}

@keyframes move-right {
  0%   { opacity:0; transform:translateX(6px); }
  100% { opacity:1; transform:translateX(0); }
}

@keyframes in-left {
  0%   { opacity:1; transform:0; }
  100% { opacity:0; transform:translateX(-100%); }
}

@keyframes in-right {
  0%   { opacity:1; transform:translateX(0); }
  100% { opacity:0; transform:translateX(100%); }
}

@keyframes in-top {
  0%   { opacity:1; transform:translateY(0); }
  100% { opacity:0; transform:translateY(-20%); }
}


/**
 * Scale keyframes
 */
@keyframes fade-scale-up {
  0%   { transform:scale(0); }
  96%  { transform:scale(1.2); }
  100% { transform:scale(1); }
}
@keyframes fade-scale-02 {
  0%   { opacity:0; transform:scale(0.9); }
  100% { opacity:1; transform:scale(1); }
}

@keyframes fade-scale-15 {
  0%   { opacity:0; transform:scale(1.5); }
  100% { opacity:1; transform:scale(1); }
}

@keyframes fade-scale-18 {
  0%   { opacity:0; transform:scale(1.8); }
  100% { opacity:1; transform:scale(1); }
}

@keyframes fade-scale-down {
  0%   { opacity:1; transform:scale(1); }
  100% { opacity:0; transform:scale(0); }
}

@keyframes scale-x {
  0%   { opacity:0; transform:scaleX(0); }
  100% { opacity:1; transform:scaleX(1); }
}


/**
 * Slide keyframes
 */
@keyframes slide-left {
  0%   { transform:translateX(-100%); }
  100% { transform:translateX(0); }
}

@keyframes slide-right {
  0%   { transform:translateX(100%); }
  100% { transform:translateX(0); }
}

@keyframes slide-left-33 {
  0%   { transform:translateX(-33%); }
  100% { transform:translateX(0); }
}

@keyframes slide-right-33 {
  0%   { transform:translateX(33%); }
  100% { transform:translateX(0); }
}

/**
 * Scale down keyframes
 */
@keyframes scale-12 {
  0%   { transform:scale(1.2); }
  100% { transform:scale(1); }
}


/**
 * Scale rotate keyframes
 */
@keyframes rotate {
  0%   { transform:rotate(0deg); }
  100% { transform:rotate(359deg); }
}

@keyframes rotate-x {
  0%   { opacity:0; transform:rotateX(90deg); }
  100% { opacity:1; transform:rotateX(0deg); }
}

@keyframes rotate-out {
  0%   { transform:rotate(0deg); }
  100% { transform:rotate(-359deg); }
}

@keyframes rotate-right {
  0%   { transform:rotate(90deg); }
  50%  { transform:rotate(0); }
  100% { transform:rotate(0); }
}

@keyframes rotate-left {
  0%   { transform:rotate(-90deg); }
  50%  { transform:rotate(0); }
  100% { transform:rotate(0); }
}


/**
 * Shake keyframes
 */
@keyframes shake {
  0%, 100% { transform:translateX(0); }
  10%      { transform:translateX(-9px); }
  20%      { transform:translateX(8px); }
  30%      { transform:translateX(-7px); }
  40%      { transform:translateX(6px); }
  50%      { transform:translateX(-5px); }
  60%      { transform:translateX(4px); }
  70%      { transform:translateX(-3px); }
  80%      { transform:translateX(2px); }
  90%      { transform:translateX(-1px); }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}