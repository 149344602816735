/*
|--------------------------------------------------------------------------
| CARDS
|--------------------------------------------------------------------------
| @package twgx
*/

.card {

  &.card-feature {
    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.open-positions {
    padding-left: 3rem;
    padding-right: 3rem;

    .card-title {
      text-align: center;
    }
  }
  
  &.card-stretched-link {
    [class*="col-"] { 
      position:static;
    }
    
    &:hover {
      .btn-link {
        color: color( 'gray-dark');
        text-decoration: none;
    
        &:before {
          transition-delay: 0s;
          width: 0;
        }
    
        &:after {
          transition-delay: 0.5s;
          width: 100%
        }
      }
    }
  }
}

.card-body {
  padding-top: $card-spacer-y;
  padding-bottom: $card-spacer-y;
}

.card-footer {
  padding-top: $card-spacer-y * 0.5;
  padding-bottom: $card-spacer-y * 0.5;
  background: transparent;

  .text-eyebrow {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
  }
}



