/*
|--------------------------------------------------------------------------
| LINKS
|--------------------------------------------------------------------------
| @package twgx
*/

.btn-primary {
  background-color:theme-color('primary');
  background-image: linear-gradient(180deg, #41AFD8, #1E9CCB, #189AC9, #2186AB); 
  background-repeat:repeat-x;
  background-size:100% 200%;
  background-position:50% 0;
  //transition: background 0.3s linear;
  box-shadow: none;

  &:hover, &:active, &:focus {
    background-position:50% 100%;
  }
}

.btn-primary, .btn-outline-primary, .btn-secondary, .btn-outline-secondary {
  &:hover, &:active, &:focus { box-shadow:$box-shadow; }
}

.btn-link {
  font-weight: 600;
  color: color('gray-dark');
  padding: 0;
  border-radius: 0;
  white-space: nowrap;
  position: relative;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    height: 3px;
    bottom: 0;
    background: theme-color( 'primary');
  }

  &:before {
    transition: width 0.25s ease-in-out;
    transition-delay: 0.5s;
    width: 100%;
    right: 0;
  }

  &:after {
    transition: width 0.25s ease-in-out, height 0.25s ease;
    width: 0;
    left: 0;
  }

  &:hover {
    color: color( 'gray-dark');
    text-decoration: none;

    &:before {
      transition-delay: 0s;
      width: 0;
    }

    &:after {
      transition-delay: 0.5s;
      width: 100%
    }
  }

  &:active {
    box-shadow: none!important;

    &:after {
      height: 5px;
    }
  }
}



// Plain-out the link...
.link-plain {
  font-weight: 400;
  border-bottom: 0;

  color: color('gray-dark');
}

