/*
|--------------------------------------------------------------------------
| MIXINS
|--------------------------------------------------------------------------
| @package twgx
*/

/**
 * Build @font-face...
 */
@mixin typeface($family, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$path}.eot?#iefix') format('embedded-opentype');

    src: url('#{$path}.woff2') format('woff2'),
    url('#{$path}.woff') format('woff'),
    url('#{$path}.eot') format('eot'),
    url('#{$path}.ttf') format('truetype');

    font-weight: $weight;
    font-style: $style;
  }
}

/**
 * Customize bg gradients...
 */
@mixin bg-gradient( $color: $accent-1, $color-alt: $accent-2, $angle: 180deg ) {
  background: $color linear-gradient($angle, $color , $color-alt) repeat-x !important;
}