/*
|--------------------------------------------------------------------------
| PAGE LAYOUTS
|--------------------------------------------------------------------------
| @note Page specific overrides.
| @package twgx
*/

// Book a demo page...
.page-book-a-demo {
  .hero {
     @include media-breakpoint-down(md) { padding-bottom:0 !important; }
    .col-md-10 { 
      @include media-breakpoint-up(md) { max-width:66% !important; flex:0 0 66% !important; }
    }
  }
}

/*
|--------------------------------------------------------------------------
| PLATFROM TEMPLATE
|--------------------------------------------------------------------------
*/
.page-template-page-platform {
  
  .content { margin-top:0; }
  
  .bg-primary-light, [data-theme=primary-light] { background:$primary-light !important; }
  
  // Sections
  section {
    
    // Hero
    &.hero {
      @include media-breakpoint-up(lg) {
        padding-top: 4.5rem !important;
        padding-bottom: 4.5rem !important;
      }
      
      .section-general--headline { margin-bottom:1.25rem; }
      .section-general--message { 
        p { font-size:1rem;
          @include media-breakpoint-up(lg) { font-size:1.225rem; }
        }
      }
      
      .btn { line-height:1.5; margin-bottom:.5rem;
        @include media-breakpoint-down(sm) {
          font-size:.9rem;
        }
      }
    }
    
    // Quote section right after features
    &.section-features {
      +.section-quote { padding-top:0 !important; }
    }
    
    // General
    &.section-general.text-center {
      > .container > .row > .col-12 {
        > .section-general--message { 
          @include media-breakpoint-up(md) { padding-left:5rem; padding-right:5rem; }
        }
      }
      .section-general--video { margin-bottom:0 !important; }
    }
    
    // Elements
    &.section-elements {
      > .container > .row > .col-12 {
        > .element-heading, > p { 
          @include media-breakpoint-up(md) { padding-left:5rem; padding-right:5rem; }
        }
      }
      .element-heading {
        h2 {
          @include media-breakpoint-up(lg) { margin-bottom:2.5rem; }
        }
        
        + p.text-center { 
          @include media-breakpoint-up(lg) { margin-top:-2rem; }
        }
      }
      .card {
        &[data-theme] { height:100%; border-radius:1rem;
          .card-icon { display:block; width:100%; height:auto; max-height:100px; }
          .card-body { padding:.5rem 2rem 2rem !important;
            .card-text { font-size:1.1rem; color:$headings-color;
              @include media-breakpoint-down(md) { margin-bottom:1rem; }
            }
          }
        }
      }
      
      .swiper-slide {
        @include media-breakpoint-down(xs) { padding-left:0; padding-right:0;
          .card { border-radius:0; }
        }
      }
    }
    
    // Features
    &.section-features {
      .feature-row {
        @include media-breakpoint-down(lg) { flex-direction:column;
          .feature-media { margin-bottom:1.5rem; }
        }
      }
    }
  }
}

.post-type-archive-review {
  .nav-primary {
    .menu-item-36118 { 
      > .nav-link { 
        &, + .dropdown-caret { color:theme-color('primary'); }
      }
      .menu-item-36135 > .dropdown-item { color:theme-color('primary'); }
    }
  }
}

.single-post {
  .nav-primary {
    .menu-item-17032 { 
      > .nav-link { 
        &, + .dropdown-caret { color:theme-color('primary'); }
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| POST
|--------------------------------------------------------------------------
*/
.single-post {
  .cta-callout {
    .cta-msg > * {
      &:last-child { 
        @include media-breakpoint-up(lg) { margin-bottom:0; }
      }
    }
    .cta-link {
      .btn {
        @include media-breakpoint-down(lg) { width:100%; }
      }
    }
  }
  .content {
    margin-top: 0;
  }
  h1 {
    font-size: 1.75rem;
    font-weight: 800;

    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }

  .post-content {
    h2 {
      font-size: 1.5em;
      font-weight: 700;
    }

    h3 {
      font-size: 1.25em;
      font-weight: 600;
    }

    p {
      font-size: 1em;
      line-height: 2;
    }
    a:not([href$=".jpg"]),
    a:not([href$=".jpeg"]),
    a:not([href$=".png"]),
    a:not([href$=".gif"]),
    a:not(.list-group-item) {
      @extend .btn;
      @extend .btn-link;
      color:$primary;
      &:before { display:none; }
      &:hover {
        &:after { transition-delay:0ms; }
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }

    button.button {
      @include button-variant($primary, $primary);
    }
    
    dl, ol, ul { display:inline-block; }
    .aligncenter { display:block; text-align:center; margin-left:auto; margin-right:auto; }
    .alignright { float:right; margin-left:1.25rem; }
    .alignleft { float:left; margin-right:1.25rem; }
    .alignright, .alignleft {
      &:after { content:""; clear:both; display:table; }
    }
    img {
      &.alignright, &.alignleft, &.aligncenter {
        @include media-breakpoint-down(sm) { width:100%; margin-left:0; margin-right:0; }
      }
    }
    
    .nav-toc { margin-bottom:2rem;
      @media (min-width:1400px) { position:absolute; width:340px !important; transform:translateX(-110%); }
      .nav-toc-title { @extend .text-eyebrow;
        @media (min-width:1400px) { padding-left:1.25rem; padding-right:1.25rem; }
      }
      .list-group {
        .list-group-item { @extend .list-group-item; padding-left:1.5rem; border-color:rgba(0,0,0,.075); color:$body-color; border-radius:0 !important; border-left:0; border-right:0; white-space:unset; text-align:left; font-weight:500; font-size:.9rem; line-height:1.2; position:relative; transition:all 350ms ease;
          &:after { display:none; }
          &:before { content:''; display:block; position:absolute; top:1rem; bottom:0; left:.25rem; width:.6rem; height:.6rem; border-radius:50%; background:rgba($secondary, 0); border:1px solid $secondary; opacity:.75; transform:scale(.8); transition:opacity 350ms ease, transform 350ms ease; }
          &.is-active {
            @media (min-width:1400px) { color:$dark; font-weight:600; }
            &:before { opacity:1; background:$secondary; transform:scale(1); }
          }
          &:hover { color:$dark; background:$accent-1;
            &:before { opacity:1; transform:scale(1); }
          }
        }
      }
    }
    .uk-sticky-placeholder { 
      @media (min-width:1400px) { display:none !important; }
    }
  }
}

/*
|--------------------------------------------------------------------------
| PRICING
|--------------------------------------------------------------------------
*/
.pricing-details {
  .card {
    border-radius: 0.5rem;
    box-shadow: 0 10px 40px rgba($black, 0.1);

    .element-list {
      text-align: left;

      .list-text {
        line-height: 1.2;
        margin-left: 0.5rem;
        font-size: 0.9em;
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| PARTNER
|--------------------------------------------------------------------------
*/
.partner-template-default {
  .section-general--message {
    .wpcf7 {
      input[type=submit] { font-weight:600; width:100%; max-width:100%; margin-top:0; }
      .wpcf7-spinner { position:absolute; left:0; right:0; margin:3.75rem auto auto auto; }
    }
  }
}