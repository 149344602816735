/*
|--------------------------------------------------------------------------
| NAVBAR
|--------------------------------------------------------------------------
| @package twgx
*/
.banner {
  .btn-banner { font-weight:600; padding:0 .5rem; color:$body-color; text-decoration:underline; text-underline-offset:1px; transition:$btn-transition;
    &:hover { color:$dark; text-decoration-color:$primary; }
  }
}
.navbar {
  width: 100%;
  z-index: 99;
  padding: 1rem;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  
  &.nav-open {
    z-index: 9999999999;
  }
}

.navbar-logo {
  max-height: 1.6rem;
  max-width: 230px;

  @include media-breakpoint-only(lg) {
    max-width: 170px;
  }
}

.navbar-cta {
  display: block;
  line-height:1.5;
  font-weight:500;

  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}

.navbar-nav {
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  .nav-link {
    font-weight: 600;
    font-size: 1.125rem;
    color: color('gray-dark');
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    transition: $btn-transition;

    @include media-breakpoint-up(lg) {
      font-size: 0.9375rem;
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
    }
  }

  // In navbar menu...
  .link-login {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }

    border:0 !important;
    position: relative;
    top:5rem !important;
    //left:-1rem;
    //width:calc(100% + 1rem);
    .nav-link {
      display: block;
      color: color('primary');
      
      line-height:1.5;
      font-weight:500;
      font-size:$btn-font-size;
      
      margin-left:-1rem;
      margin-right:-1rem;
      
      .link-text {
        display:inline-block;
        color:$primary;
        padding:.75rem 2rem !important;
      }
      
            
      &:hover, &:active, &:focus {
        .link-text { color:$white; }
      }
    }
  }
}

.navbar-primary {
  .navbar-content {
    @include media-breakpoint-down(md) {
      margin:-2.5rem 0 12rem 0;
    }
  }
}

.nav-primary {
  .dropdown-item { transition:$btn-transition; }
  .menu-item-desc { font-weight:$font-weight-base; font-size:.8rem; line-height:1.5; color: color('gray-dark') !important; white-space:initial; display:inline-block; display:inline-block; min-width:300px; max-width:330px; margin-top:.15rem; }
  .has-desc {
    .link-text { font-weight:$font-weight-bold; font-size:.9rem !important;
      i { transition:$btn-transition; }
    }
  }
  
  @include media-breakpoint-down(md) {
    .menu-icon {
      display: none;
    }
    //.nav-item {
    //  border-bottom: 1px dashed #BFDBE7;
    //}

    .nav-link {
      flex: 1;

      & + .dropdown-caret {
        cursor: pointer;
        margin-left: 1rem;
        padding:.25rem;
        font-size: 1.25rem;
      }
    }
  }

  .nav-item {
    @include media-breakpoint-up(xl) {
        padding-right: 1rem;
    }

    &.active,
    &.current-menu-parent {
      .nav-link,
      .dropdown-caret {
        color: theme-color('primary');
      }
    }
    
    &:hover, &:active, &:focus {
      .nav-link,
      .dropdown-caret {
        color: theme-color('secondary') !important;
      }
    }

    &.dropdown {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .dropdown-menu {
        flex: 1 1 100%;
        padding:1.5rem 0;

        @include media-breakpoint-down(md) {
          padding: 0;
          margin: 0;
          border: 0;
          //border-top:1px solid #B0B6BA;
          border-radius:0;
          box-shadow: none;

          .dropdown-item {
            padding: 0;
          }
        }
        
        .dropdown-container { padding:0;
          > li { list-style:none;
            //@include media-breakpoint-up(md) { float:left; }
            > .sub-menu { padding:0; margin-bottom:.75rem;
              @include media-breakpoint-up(sm) { display:grid; grid-template-columns:50% 50%; width:100%; margin-left:-.15rem; }
              > li { list-style:none; 
                @include media-breakpoint-up(sm) { min-width:180px; }
              }
              .link-text {
                display:inline-flex;
                align-items:center;
                font-weight:500;
                .style-svg { width:2em; height:1.5em; margin-right:.25rem; transition:$btn-transition;
                  g, path { transition:stroke 350ms ease, fill 350ms ease; }
                }
              }
            }
          }
        }

        .dropdown-item {
          display: flex;
          align-items: center;
          background: transparent !important;

          .menu-icon {
            margin-right: 0.5rem;
          }

          .link-text {
            padding:.5rem 0;
            @include media-breakpoint-up(md) {
              padding: 0.85rem 0;
            }
            //flex-grow: 1;
            font-size: 0.85rem;
            background: transparent !important;
            transition: $btn-transition;
          }
          
          &:active,
          &:focus,
          &:hover {
            background: transparent !important;

            .link-text {
              color:theme-color('secondary');
              .style-svg {
                g, path  { stroke:theme-color('secondary'); }
              }
            }
          }
          
          &:active,
          &:focus,
          &:hover {
            .link-text {
              .style-svg { 
                @include media-breakpoint-up(md) { transform:scale(1.15); }
              }
            }
            &.has-desc {
              .link-text {
                i { transform:translateX(.25rem); }
              }
            }
          }

          &.active {
            .link-text {
              color: theme-color('primary');
              font-weight: $font-weight-bold;
              .style-svg { color: theme-color('primary');
                g, path  { stroke:theme-color('primary'); }
              }
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        position: relative !important;

        .dropdown-menu {
          //left:-10vw;
          margin-top:1px !important;
          margin-bottom:0;
          position:fixed;
          z-index:-1;
          left:0;
          right:0;
          top:100%;
          margin:auto;
          opacity: 0;
          display: block !important;
          pointer-events: none;
          transition: opacity 0.25s linear;
          transition-delay: 0.15s;
          border-radius: 0!important;
          border: 0!important;
          width: auto;
          background: $white;
          
          &:before { display:none; }

          //&:before {
          //  background-color: $white;
          //  border: 1px solid rgba($black, .05) !important;
          //  position: absolute;
          //  z-index: -1;
          //  top: 0;
          //  height: 110%;
          //  border-top: 1px solid rgba(0, 0, 0, .05) !important;
          //  content: '';
          //  box-shadow: 0 1rem 1rem rgba($black, 0.075);
          //  left: -100vw;
          //  right: -100vw;
          //}

          .dropdown-container {
            max-width:1180px;
            margin:auto;
            padding:0;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 20rem;
            min-height: 100%;
            justify-content: end;
            
            > .nav-item { max-width:60%;
              &,.menu-item-36133 { max-width:none; flex-direction:column; align-items:flex-start; align-content: center;
                //.has-desc { width:100%; }
                .sub-menu { width:auto !important; }
              }
            }
            > .border-lg-right { border-right:1px solid #B0B6BA; }
            > .border-lg-left { border-left:1px solid #B0B6BA; padding-left:2rem; }
          }

          .dropdown-item {
            display: flex;
            padding: 0;
            width:auto;

            .link-text {
              //border-bottom: 1px dashed #BFDBE7;
            }

            &:last-child,
            &:nth-child(4n) {
              .link-text {
                border-bottom: 0 !important;
              }
            }

            &:nth-child(n + 8) {
              .link-text {
                margin-right: 0;
              }
            }
          } // .dropdown-item
        } // .dropdown-menu

        // .dropdown hover
        &:hover {
          > .nav-link .link-text,
          > .dropdown-caret {
            color: theme-color('secondary');
          }

          .dropdown-menu {
            transition-delay: 0s;
            opacity: 1;
            z-index:99;
            pointer-events: all;
          }
        }

        &.menu-item-object-feature {
          .dropdown-container {
            transform: translateX(-130%);

            .link-text {
              margin-right: 2rem;
            }
          }
        }

        .nav-link {
          padding-right: 0.25rem;
        }
      } // desktop-breakpoint
    } // .dropdown
  }//.nav-item
}

/*
|--------------------------------------------------------------------------
| NAVBAR: SIMPLE
|--------------------------------------------------------------------------
*/
.navbar-simple {
  padding-top: 1rem;
  padding-bottom: 1rem;

  .navbar-brand {
    width: 50%;

    .navbar-logo {
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  .navbar-content {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;

    .btn {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      .btn {
        display: block;
      }
    }
  }
}

/**
 * Monkey punch patch to deal with hover "touchiness"
 */
@include media-breakpoint-up(lg) {
  .nav-wrapper .nav-primary > .menu-item:hover:before {
    content: '';
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 100%;
    bottom: -325px;
  }
}
.nav-wrapper .nav-primary > .menu-item {
  backface-visibility:hidden;
  @include media-breakpoint-down(md) { 
    border-bottom:1px solid #B0B6BA;
    padding:1rem !important;
    margin: 0 !important;
  }
  margin-right: 2rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  margin-left: 2rem;
  padding: 0 !important;

  @include media-breakpoint-only(lg) {
    margin-left:1rem;
    margin-right: 1rem;
  }
}
.nav-wrapper .nav-primary > .menu-item > .nav-link { padding:0 .25rem 0 0 !important; }
.nav-wrapper .nav-primary > .menu-item > .dropdown-menu { 
  @include media-breakpoint-down(md) { margin-top:1.25rem !important; margin-bottom:1.25rem; }
}

/*
|--------------------------------------------------------------------------
| NAVBAR: TOGGLER
|--------------------------------------------------------------------------
*/
.navbar-toggler {
  border: 0;
  padding: 0;
  width: 26px;
  border-radius: 0;

  &:focus {
    outline: none !important;
  }

  span {
    width: 100%;
    height: 2px;
    background: color('gray-dark');
    display: block;
    position: relative;
    transform-origin: 100%;
    transition: transform 0.25s ease;

    + span {
      margin-top: 4px;
    }
    &:nth-of-type(1) { top: -4px; }
    &:nth-of-type(2) { top: -2px; transform-origin: 50% 50%; transition: transform 0.25s ease 0.25s; }
    &:nth-of-type(3) { top: 0; }
  }

  .nav-open & {
    span {
      &:nth-of-type(1) { transform: rotate(-45deg) translateY(-2px) translateX(2px); }
      &:nth-of-type(2) { transform: scaleX(0); transition-delay: 0ms; }
      &:nth-of-type(3) { transform: rotate(45deg); }
    }
  }
}

@include media-breakpoint-down(md) {
  .nav-wrapper {
    box-shadow:0 2rem 1.75rem rgba($black, .25);
    position: fixed;
    background: #fff;
    padding: 1rem;
    width: 100vw;
    top: 75px;
    left: 0;
    bottom: 0;
    transition: transform 0.4s ease-out;
    transform: translateX(140%) translateY(10%) scale(.4) rotate(-24deg);
    transform-origin:top left;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;

    .nav-open & {
      transform: translateX(0%);
    }
  }
}

/*
|--------------------------------------------------------------------------
| NAVBAR: ADD-ONS
|--------------------------------------------------------------------------
*/

.white-label-logo {
  max-height: 50px;
  max-width: 150px;
  align-self: center;
}

.white-label-logo,
.white-label-title {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid $border-color;
}