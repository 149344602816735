/*
|--------------------------------------------------------------------------
| SECTIONS
|--------------------------------------------------------------------------
| @note Page cross-sections
| @package twgx
*/



/*
|--------------------------------------------------------------------------
| SECTION: GENERAL
|--------------------------------------------------------------------------
*/
.section-general {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/*
|--------------------------------------------------------------------------
| SECTION: FEATURES
|--------------------------------------------------------------------------
*/
.callout-scale-up-center {
  animation: scale-up-center 0.5s cubic-bezier(0.645, 0.045, 0.355, 1.000) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale($callout-scale-start);
    opacity: 0;
  }
  100% {
    transform: scale($callout-scale-end);
    opacity: 1;
  }
}

.section-features {
  $media-shadow: 10px 10px 50px rgba(59,59,59, .1);

  .feature-figure {
    margin: 0;
    position: relative;
    perspective: 2000;
    perspective-origin: 0 50%;
    width: 100%;

    &.has-bg {
      background: $white;
      text-align: center;
      padding: 1.5rem;
      box-shadow: $media-shadow;
      border-radius: 0.5rem;
      border: 1px solid #E5E5E5;
    }

    &.has-callout {
      width: 90%;
    }

    .feature-callout {
      box-shadow: $media-shadow;
      position: absolute;
      right: -10%;
      top: 40%;
      opacity: 0;
      transform: scale($callout-scale-start);
    }
  }

  .feature-image {
    max-width: 100%;
    align-self: center;
  }

  .feature-row {
    // Always show text first on mobile
    flex-direction: column-reverse;

    @include media-breakpoint-up(lg) {
      flex-direction: row;

      &.feature-row-reverse {
        flex-direction: row-reverse;

        .feature-media {
          display: flex;
          justify-content: flex-end;

          .feature-figure {
            perspective-origin: 100% 50%;

            .feature-callout {
              left: -10%;
            }
          }
        }
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| SECTION: ELEMENTS
|--------------------------------------------------------------------------
*/
.section-elements {
  .element-heading {
    width: 100%;
    margin: auto;

    @include media-breakpoint-up(lg) {
      width: 80%;
    }
  }

  .card .element-heading {
    width: 100%;
  }
}

/*
|--------------------------------------------------------------------------
| SECTION: QUOTE
|--------------------------------------------------------------------------
*/
.section-quote {
  .quote {
    @include bg-gradient(color('dark-1'), color('dark-2'), 45deg);

    font-size: $paragraph-size;
    font-weight: 300;
    box-shadow: 0 5px 20px rgba($black, 0.2);
    border-radius: 1rem;

    .card-body {
      >:last-child { margin-bottom: 0!important; }
    }
  }

  .quote--avatar {
    max-width: 70px;
    box-shadow: 5px 5px 20px rgba(0,0,0, .07);
    position: absolute;
    top: -2rem;
  }

  .quote--meta {
    font-size: 1em;
    margin-bottom: 1.5em;
  }

  .quote--source {
    color: theme-color('secondary');
    text-transform: uppercase;
    font-weight: 600;
  }

  .quote--affiliation,
  .quote--quote {
    color: $white;
    margin-bottom: 1.5em;
  }

  .quote--quote {
    font-size: 1.45rem;

    @include media-breakpoint-up(md) {
      font-size: 1.5em;
    }
  }
}

/*
|--------------------------------------------------------------------------
| SECTION: TIMELINE
|--------------------------------------------------------------------------
*/
.section-timeline {
  $circle-size: 80px;
  $circle-size-lg: 90px;
  $circle-text-color-alt: color('gray-dark');
  $line-margin: $circle-size * 0.5;
  $line-margin-lg: auto;
  $line-color: theme-color('primary');

  .timeline-line {
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-left: 2px dashed $line-color;
      margin: $circle-size $line-margin;
      z-index: 0;

      @include media-breakpoint-up(md) {
        margin: 100px $line-margin-lg;
      }
    }
  }

  .timeline-circle {
    display: flex;
    flex-direction: column;
    border-radius: 50%;
    width: $circle-size;
    height: $circle-size;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    box-shadow: 10px 10px 40px rgba(#C2C0C0, 0.2);

    @include media-breakpoint-up(md) {
      width: $circle-size-lg;
      height: $circle-size-lg;
    }

    .timeline-circle--top,
    .timeline-circle--bottom {
      @extend .text-eyebrow;
      font-weight: 600;
      font-size: 0.875rem;
      margin-bottom: 0.125rem;
      color: $white !important;
    }

    .timeline-circle--bottom-alt {
      font-size: 2rem;
      font-family: "Courier", sans-serif;
      font-weight: bold;
      color: $circle-text-color-alt;
      line-height: 1;
    }

    &.timeline-circle--last {
      .timeline-circle--bottom {
        color: $circle-text-color-alt!important;
      }
    }
  }
}


.section-align-cards {
  .col-md-10 { max-width:100%; flex:0 0 100%;
    > h2 {
      @include media-breakpoint-up(lg) { padding-left:8rem; padding-right:8rem; }
    }
  }
  .col-12.col-md {
    @include media-breakpoint-down(md) { margin-bottom:1rem; }
  }
  .card { height:100%; border-radius:1rem; overflow:hidden;
    .card-icon { max-height:100px; margin-top:2rem !important; }
    .card-body { /* display:flex; flex-direction:column; height:100%; justify-content:center; */ padding:.5rem 2rem 2rem !important;
      .card-title:empty, .card-text:empty { display:none; }
      .card-title { margin-bottom:0;
        + .card-text:not(:empty) { margin-top:1rem; }
      }
      .card-text { font-size:1.1rem; color:$headings-color; }
    }
  }
}