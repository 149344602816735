/*
|--------------------------------------------------------------------------
| PARTS
|--------------------------------------------------------------------------
| @package twgx
*/

.avatar {
  max-width: 70px;
  box-shadow: 5px 5px 20px rgba(0,0,0, .07);
  border-radius: 50%;

  &.floating {
    position: absolute;
    top: -2rem;
  }
}

.bull {
  font-size: 1.2em;
  line-height: 1em;
  color: color('secondary');
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

/*
|--------------------------------------------------------------------------
| POSTS: CATEGORY FILTER
|--------------------------------------------------------------------------
*/
.post-filter {

  .dropdown {
    display: inline-block;
  }

  .post-filter--label {
    font-size: 1rem;
  }

  .post-filter--dropdown {
    @extend .text-eyebrow;
    text-decoration: none;
    color: theme-color('secondary');
    font-size: 1rem;
  }

  .post-filter--dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }

  .post-filter--dropdown-menu-item {
    font-size: 1rem;
  }

  .post-filter--search-toggle {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    transition: all 0.3s ease;
    border-radius: 2rem;
    width: 45px;
    height: 45px;
    margin: auto 0.25rem auto 0;

    i {
      transition: color 0.3s ease;
    }

    &:hover {
      background-color: theme-color('secondary');
      box-shadow: 0 1px 8px rgba(0,0,0,.15);

      i {
        color: white!important;
      }
    }
  }



  .post-filter--search {
    border-radius: 2rem;
  }
}

/*
|--------------------------------------------------------------------------
| POSTS: LIST
|--------------------------------------------------------------------------
*/
.post-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .post-list-item {
    .post-list-link {
      padding: 1rem;
      border-bottom: 1px dashed #BFDBE7;
      transition: background 0.2s linear;
      display: block;
      font-size: 0.875rem;
      color: color('gray-dark');

      &:hover {
        background: #F5FAFC;
        text-decoration: none;
      }
    }

    &:last-child {
      .post-list-link {
        border-bottom: none;
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| BLOG: SIDEBAR
|--------------------------------------------------------------------------
*/
.blog-sidebar {
  .card-deck {
    column-count: 1;

    @include media-breakpoint-only(md) {
      column-count: 3;
    }
  }
}

.textwidget,
.widget-content {
  h4 {
    color:  $white;
  }

  p {
    color: $white;
  }

  a {
    @extend .btn;
    @extend .btn-link;
  }
}

/*
|--------------------------------------------------------------------------
| PAGINATION
|--------------------------------------------------------------------------
*/
.pagination {
  margin-bottom: 0;

  .page-item {
    &.active {
      .page-link {
        color: inherit;
        background-color: transparent;
        border: 1px solid theme-color('primary');
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        border-radius: 0;
      }
    }
  }
  .page-link {
    border: 1px solid transparent;
    background-color: transparent;
    transition: background-color 0.3s linear, color 0.3s linear, border-color 0.3s linear;
    text-align: center;

    &:hover {
      color: $white;
      background-color: theme-color('primary');
      border-color: theme-color('primary');
    }
  }

  .page-numbers.current {
    padding: 0.5rem 0.75rem;
    display: block;
    border: 1px solid transparent;
    line-height: 1.25;
  }


}

ul.page-numbers {
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style: none;

    .page-numbers {
      padding: 0.5rem 0.75rem;
      line-height: 1;
    }

    a.page-numbers {
      transition: all .25s linear;

      &:hover {
        color: $white;
        background-color: theme-color('primary');
        border-color: theme-color('primary');
      }
    }

    span.page-numbers {
      &.dots {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      &.current {
        border: 1px solid transparent;
        line-height: 1;
      }
    }
  }
}

/*
|--------------------------------------------------------------------------
| ICONS
|--------------------------------------------------------------------------
*/
.card-icon,
.feature-icon {
  filter: drop-shadow(10px 10px 8px rgba(0,0,0,0.08));
}

.card-icon {
  max-height: 50px;
}

.feature-icon {
  max-width: 100%;
  margin-right: 1rem;

  @include media-breakpoint-up(md) {
    height: 50px;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}

.menu-icon {
  max-width: 30px;
  max-height: 30px;
}

.roi-icon {
  max-height: 50px;
}

.social-link {
  .social-icon {
    color: color('social-icon');
    border-color: color('social-icon');
    padding: 0.35em;
    min-width: 1.75rem;
    text-align: center;
    margin-right: 0.15rem;
    transition: background 0.3s linear, color 0.3s linear;
  }

  &:hover {
    text-decoration: none;

    .social-icon {
      color: $white;
      background: color('social-icon');
    }
  }
}

/*
|--------------------------------------------------------------------------
| DROPDOWNS
|--------------------------------------------------------------------------
*/
.dropdown {
  .dropdown-caret {
    transition: transform 0.3s ease-in-out;
  }

  &:hover,
  &.show {
    .dropdown-caret {
      transform: rotateZ(180deg);
    }
  }
}

/*
|--------------------------------------------------------------------------
| EMBED
|--------------------------------------------------------------------------
*/
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*
|--------------------------------------------------------------------------
| COOKIE POLICY
|--------------------------------------------------------------------------
*/
.cookie-policy {
  position: fixed;
  line-height: 1.4;
  margin-bottom: 0;
  bottom: 0.4rem;
  right: 0.4rem;
  left: 0.4rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.4);
  transition: opacity 350ms ease, transform 350ms ease;
  z-index: 99999;

  @include media-breakpoint-up(lg) {
    max-width: 22rem;
  }

  p {
    font-size: 0.875rem;
  }
  .alert-link {
    font-weight: 400;
  }
}

/*
|--------------------------------------------------------------------------
| CALLOUT
|--------------------------------------------------------------------------
*/
.callout { transition:$btn-transition; position:relative;
  background:$primary-light !important;
  border:1px solid rgba(#1E9CCB, 0);
  //@include bg-gradient(#E6F1F6, #F2FAFC, 90deg);
  border-radius: 1rem;
  
  h2 {
    @include media-breakpoint-down(md) { font-size:2rem; }
  }

  .callout--message {
    @include media-breakpoint-down(md) {
      font-size: 1.7rem;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 5rem;
    }
  }

  .btn {
    //display: block;
    
    line-height:1.5;
    @include media-breakpoint-down(sm) {
      font-size:.9rem;
    }
    @include media-breakpoint-up(md) {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
      margin-bottom: 0!important;
    }
  }
  
  &.callout-hover {
    &:hover, &:focus-within {
      border:1px solid rgba(#1E9CCB, .1);
      box-shadow: 0 .5rem 1rem rgba($black, .05);
      .btn {
        box-shadow:$box-shadow;
      }
    }
  }
}


/**
 * Swiper
 */
:root {
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-pagination-bullet-inactive-opacity: .9;
  --swiper-pagination-bullet-width: .75rem;
  --swiper-pagination-bullet-height: .75rem;
}
.swiper-container {
  .swiper-slide { height:auto; }
  .swiper-pagination { bottom:3rem; }
  .swiper-pagination-bullet { border:1.5px solid $themeColor; }
}


/**
 * Accordion
 */
.section-elements p.text-center + .tab-accordion { margin-top:2rem;
  @include media-breakpoint-up(lg) { margin-top:4rem; }
}
.tab-accordion {
  @include media-breakpoint-up(lg) { display:flex; flex-wrap:wrap; justify-content:space-between; overflow-x:hidden; margin-top:1rem;
    > *:nth-child(even) { order:1; width:100%; }
  }
  
  .nav-link { color:$gray-dark; font-weight:$font-weight-bold; appearance:none; border:0; background:transparent; position:relative; transition:$btn-transition; outline:0 !important; box-shadow:none !important; width:100%; text-align:left; padding-top:1rem; padding-bottom:1rem;
    @include media-breakpoint-between(sm, lg) { font-size:.85rem; }
    @include media-breakpoint-up(lg) { width:auto; text-align:center; font-weight:$font-weight-base; text-stroke:1px $gray-dark; -webkit-text-stroke:1px $gray-dark; padding-top:.5rem; padding-bottom:.5rem; }
    &:after, &:before { content:''; inset:auto 0 1px 0; position:absolute; z-index:1; transition:$btn-transition; }
    &:before { background:#B0B6BA; height:1px; }
    &:first-child {
      &:before { 
        @include media-breakpoint-up(lg) { width:100vw; background:#B0B6BA !important; }
      }
    }
    &:after { content:fa-content($fa-var-chevron-right); font-family:'Font Awesome 5 Pro'; font-weight:300; @include fa-icon; width:1em; height:1em; background:transparent; left:auto; right:1rem; top:0; margin:auto; transform:rotate(-90deg); vertical-align:middle; text-align:center;
      @include media-breakpoint-up(lg) { content:''; position:absolute; background:theme-color('primary'); width:auto; height:3px; top:auto; bottom:0; left:0; right:0; transform:rotate(0deg); }
    }
    &.collapsed { text-stroke:0; -webkit-text-stroke:0;
      @include media-breakpoint-up(lg) { color:$gray-light; }
      &:hover { color:$gray-dark; 
        @include media-breakpoint-up(lg) { text-stroke:.5px $gray-dark; -webkit-text-stroke:.5px $gray-dark; }
        &:after { opacity:1; }
      }
      &:after { transform:rotate(90deg); font-weight:300;
        @include media-breakpoint-up(lg) { opacity:0; transform:rotate(0deg); }
      }
    }
  }
  
  .tab-card {
    @include media-breakpoint-down(sm) { border-bottom:1px solid #B0B6BA; }
    @include media-breakpoint-between(sm, lg) { padding-left:1rem; padding-right:1rem; }
    .tab-img { margin:0 -2.5rem; 
      @include media-breakpoint-up(sm) { margin:1rem 0 0 2rem; border-radius:1rem; }
      &[data-theme="primary-light"] { background:linear-gradient(120deg, #C1E0EE, #F0F7F8) !important; }
      &[data-theme="secondary-light"] { background:linear-gradient(120deg, #d2e9d0, #ECF6EA) !important; }
      img { display:inline-block; width:calc(100% - 4rem); height:auto; border-radius:1rem; box-shadow:$box-shadow; transform:translate3d(0, -1rem, 0); opacity:.8; transition:$btn-transition;
        @include media-breakpoint-up(sm) { width:100%; margin:0; transform:translate3d(-1rem, -1rem, 0); }
      }
      
      .tab-img-group {
        @include media-breakpoint-down(xs) { width:160vw; transform:translate3d(0,0,0) !important; }
        > div { display:flex; flex-wrap:nowrap; gap:1.5rem;
          @include media-breakpoint-up(sm) { gap:1rem; transform:translate3d(0,0,0) !important; }
          div { width:33.3333%; }
          img { width:100%; border-radius:.5rem; }
        }
        
        &.tab-50 {
          @include media-breakpoint-down(xs) { width:110vw; }
          > div { gap:2rem;
            @include media-breakpoint-up(sm) { gap:2rem; }
            div { width:50%; }
          }
        }
      }
    }
    
    &.show {
      .tab-img {
        img { transform:translateY(-2rem); transition-duration:400ms; transform-timing-function:ease-in; opacity:1; background:$white;
          @include media-breakpoint-up(sm) { transform:translate3d(-2rem, -2rem, 0); }
        }
      }
    }
  }
}

#lang_choice_1 { @extend .custom-select; @extend .custom-select-sm; }
select#lang_choice_1.pll-switcher-select { width:auto; height:calc(1em + 1rem + 4px); font-size:12px; border-width:1px; padding-right:1.5rem; padding-left:.75rem; background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%231e9ccb' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px; width:auto; box-shadow:none; border-color:darken($border-color, 4%);
  &:hover { border-color:$primary; }
}